* ##{$app} body, ##{$app} {
    margin-top: 0;
}

html {
    font-size: $font-base-size;
    line-height: $font-base-height;
    
    @media (max-width: $screen-lg-min) {
        font-size: $font-base-size - ($font-base-size - modular-scale(-1, $font-base-size)) / 2;
    }
    
    @media (max-width: $screen-md-min) {
        font-size: modular-scale(-1, $font-base-size);
    }
}

body {
    @include clearfix;
    background: #222;
    font-size: inherit;
    line-height: inherit;
}

div, section, header, main, footer, aside, article {
    position: relative;
}

#{headings()}, p {
    &.pull-left, &.pull-right {
        @include margin(0 null 0);
    }
}

#{headings()} {
    text-rendering: auto;
}

a {
    display: inline-block;
    text-decoration: none;
    transition: color $animation-duration;
    
    &:hover {
        color: lighten($link-color, 10%);
        
        &:not([class]):after {
            padding: 0 4px;
            background: lighten($link-color, 10%);
        }
    }
    
    &:hover, &:active, &:focus {
        text-decoration: none;
    }
    
    &:not([class]):after {
        content: '';
        display: block;
        @include absolute((top: 100%, left: 50%));
        padding: 0;
        @include size(100%, 1px);
        background: $link-color;
        box-sizing: content-box;
        transform: translateX(-50%);
        transition: padding $animation-duration, background $animation-duration;
    }
    
    img {
        border: none !important;
    }
}

p {
    margin-bottom: 24px;
    @include font-size($font-base-size);
    
    &:last-child {
        img {
            @at-root {
                html & {
                    margin-bottom: 0;
                }
            }
        }
    }
    
    a {
        position: relative;
    }
    
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 16px;
        @include policy(margin);
        
        &.alignnone {
            + img.alignnone {
                margin-left: 16px;
            }
        }
        
        &.alignleft {
            float: left;
            margin-right: 16px;
        }
        
        &.alignright {
            float: right;
            margin-left: 16px;
        }
    }
}

fieldset {
    padding: 0;
    border: 0;
    
    legend {
        padding: 0 0 4px 8px;
        border-bottom: 1px solid $accent-color;
    }
}

select[multiple], select[size] {
    overflow-x: hidden;
    overflow-y: scroll;
}

address {
    margin: 1em 0;
    font-style: normal;
}

table {
    tr {
        transition: background-color $animation-duration;
    }
}

.no-list {
    @include policy(no-list);
}

.hidden {
    display: none !important;
}

// IE fix
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    template {
        display: none;
    }
}
