$side-img-angle: 15deg;

@include component(side-img) {
    @include absolute((left: 0));
    @include size(100%, 500%);
    overflow: hidden;
    
    @include part(container) {
        height: 100%;
        min-height: 320px;
        overflow: hidden;
    }
    
    @include part(bg) {
        display: block;
        @include absolute((left: 0));
        @include size(100%, 20%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        
        @include part(logo) {
            @include absolute((top: 12%, right: 24%, bottom: 12%, left: 12%));
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
        
        @include option(secondary-main) {
            background-color: $grey;
        }
        
        @include option(inverse) {
            background-color: $inverse-main-color;
        }
        
        @include option(primary) {
            background-color: $primary-color;
        }
        
        @include option(secondary) {
            background-color: $secondary-color;
        }
        
        @include option(accent) {
            background-color: $accent-color;
        }
    }
    
    + {
        @include part(line) {
            position: absolute;
            @include size(2px, 100%);
            padding: 32px 0;
            background: $tertiary-border-color;
            box-sizing: content-box;
            z-index: 11;
        }
    }
    
    @include option(left-up, left-down) {
        + {
            @include part(line) {
                left: 0;
                transform-origin: left bottom;
            }
        }
    }
    
    @include option(left-up, right-up) {
        transform: rotate($side-img-angle);
        
        @include part(bg) {
            transform: rotate($side-img-angle * -1);
        }
    }
    
    @include option(left-up, right-down) {
        bottom: 0;
        
        @include part(bg) {
            bottom: 0;
        }
        
        + {
            @include part(line) {
                bottom: 0;
            }
        }
    }
    
    @include option(left-down, right-up) {
        top: 0;
        
        @include part(bg) {
            top: 0;
        }
        
        + {
            @include part(line) {
                top: 0;
                transform-origin: left top;
            }
        }
    }
    
    @include option(left-down, right-down) {
        transform: rotate($side-img-angle * -1);
        
        @include part(bg) {
            transform: rotate($side-img-angle);
        }
    }
    
    @include option(right-up, right-down) {
        + {
            @include part(line) {
                right: 0;
            }
        }
    }
    
    @include option(left-up) {
        transform-origin: left bottom;
        
        @include part(bg) {
            transform-origin: left bottom;
        }
        
        + {
            @include part(line) {
                transform: rotate($side-img-angle) translateY(22.627417px);
            }
        }
    }
    
    @include option(left-down) {
        transform-origin: left top;
        
        @include part(bg) {
            transform-origin: left top;
        }
        
        + {
            @include part(line) {
                transform: rotate($side-img-angle * -1) translateY(-22.627417px);
            }
        }
    }
    
    @include option(right-up) {
        transform-origin: right top;
        
        @include part(bg) {
            transform-origin: right top;
        }
        
        + {
            @include part(line) {
                transform: rotate($side-img-angle) translateY(-22.627417px);
            }
        }
    }
    
    @include option(right-down) {
        transform-origin: right bottom;
        
        @include part(bg) {
            transform-origin: right bottom;
        }
        
        + {
            @include part(line) {
                transform: rotate($side-img-angle * -1) translateY(22.627417px);
            }
        }
    }
}
