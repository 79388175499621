$main-nav-item-padding: 8px !default;
$default-nav-indicator-height: 12px !default;
$nav-breakpoint: $screen-md-min !default;

$nav-breakpoint-min: $nav-breakpoint;
$nav-breakpoint-max: $nav-breakpoint-min - 1;

@include location(nav-open) {
    overflow: hidden;
}

@include component(nav) {
    transition: box-shadow 0s $animation-duration;
    
    @include location(nav-open) {
        @include important {
            box-shadow: none;
            transition: box-shadow 0s;
        }
        
        @include component(toggle-icn) {
            @extend "#{component(toggle-icn)}#{option(active)}";
        }
        
        @include part(section) {
            @include option(desktop) {
                @extend %z-depth-1;
                transition: height $animation-duration, box-shadow 0s;
            }
        }
        
        @media (max-width: $nav-breakpoint-max) {
            @include part(section) {
                @include option(desktop) {
                    overflow: scroll;
                }
            }
            
            @include option(default) {
                @include part(section) {
                    @include option(desktop) {
                        @include component(nav) {
                            @include part(item) {
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include part(section) {
        @include clearfix;
        
        @include option(desktop) {
            @media (max-width: $nav-breakpoint-max) {
                @include absolute((top: $nav-height, left: 0));
                width: 100%;
                max-height: 100vh;
                max-height: calc(100vh - #{$nav-height});
                background: rgba($inverse-main-color, .9);
                color: $inverse-text-color;
                overflow: hidden;
                transition: height $animation-duration, box-shadow 0s $animation-duration;
            }
            
            @media (min-width: $nav-breakpoint-min) {
                height: $nav-height;
                transition: none;
            }
        }
        
        @include option(mobile) {
            @media (min-width: $nav-breakpoint-min) {
                display: none;
            }
        }
    }
    
    @include part(item) {
        position: relative;
        padding: 0 16px;
    }
    
    @include option(default) {
        @include fixed((top: 0, left: 0));
        @include size(100%, $nav-height);
        background: $nav-color;
        color: color-contrast($nav-color, $text-color, $faded-text-color);
        font-family: $heading-font;
        font-weight: $fw-regular;
        @include font-size(18px);
        text-transform: uppercase;
        z-index: 99999;
        
        @include location(has-admin-bar) {
            top: 32px;
        }
        
        @include part(section) {
            margin: 0 12px;
            
            &:first-child {
                margin-left: 0;
            }
            
            &:last-child {
                margin-right: 0;
            }
        }
        
        @include part(item) {
            float: left;
            height: $nav-height;
            padding: 0 32px 0 16px;
            margin: 0 16px;
            line-height: $nav-height;
            transition: color $animation-duration;
            
            &:first-child {
                margin-left: 0;
            }
            
            &:last-child {
                margin-right: 0;
            }
            
            &:after {
                content: '';
                display: block;
                @include absolute((top: -2px, right: 0));
                @include size(2px, $nav-height - 16px);
                transform: rotate(15deg);
                background: $inverse-faded-text-color;
                transition: background $animation-duration;
            }
            
            &:hover {
                color: $accent-color;
                
                &:after {
                    background: $accent-color;
                }
            }
            
            @include part(content) {
                
            }
            
            @include option(logo, icn) {
                padding: 0 24px;
                
                &:before, &:after {
                    content: none;
                }
            }
            
            @include option(logo) {
                @include padding(16px null 16px);
                @extend %valign-center;
                
                img {
                    max-height: 100%
                }
            }
            
            @include option(icn) {
                line-height: $nav-height;
                @include font-size(24px);
            }
            
            @include option(active) {
                &:after {
                    background: $accent-color;
                }
            }
            
            @include option(hide-desktop) {
                @media (min-width: $nav-breakpoint-min) {
                    display: none;
                }
            }
            
            @include option(hide-mobile) {
                @media (max-width: $nav-breakpoint-max) {
                    display: none;
                }
            }
        }
        
        @media (max-width: $screen-xs-max) {
            @include padding(null 0 null 0);
        }
        
        @media (max-width: $nav-breakpoint-max) {
            @include part(item) {
                float: none;
                width: 100%;
            }
            
            @include part(section) {
                @include option(desktop) {
                    height: 0;
                    margin: 0;
                    
                    @include component(nav) {
                        @include part(item) {
                            margin: 0;
                            padding: 0 32px;
                            transition: background $animation-duration;
                            
                            &:hover {
                                background: rgba(#fff, .1);
                                color: inherit;
                            }
                            
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include option(simple) {
        margin: -2px -6px;
        font-weight: $fw-semi-bold;
        
        @include part(item) {
            width: 25%;
            padding: 2px 0;
            
            &:hover {
                @include part(content) {
                    &:after {
                        width: 100%;
                        left: 0;
                    }
                }
            }
            
            @include part(content) {
                display: inline-block;
                position: relative;
                padding: 6px;
                
                &:after {
                    content: '';
                    display: block;
                    @include absolute((bottom: 0, left: 50%));
                    @include size(0, 2px);
                    background: $accent-color;
                    transition: all $animation-duration;
                }
            }
            
            @include option(active) {
                @include part(content) {
                    &:after {
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
}

html.ie.crie {
    @include component(nav) {
        &#{option(default)} {
            top: 56px;
        }
    }
    
    @include location(has-admin-bar) {
        @include component(nav) {
            &#{option(default)} {
                top: 56px + 32px;
            }
        }
    }
}

html.ie-9 {
    @include component(nav) {
        &#{option(default)} {
            top: 36px;
        }
    }
    
    @include location(has-admin-bar) {
        @include component(nav) {
            &#{option(default)} {
                top: 36px + 32px;
            }
        }
    }
}
