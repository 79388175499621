@include component(logo) {
    width: 256px;
}

@include component(icn-logo) {
    width: 100%;
    max-width: 96px;
    margin: 24px auto;
    @include policy(margin);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('#{asset('img/icn-logo-light.png')}');
    
    &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
}
