@include component(icn) {
    @include option(text-left) {
        padding-left: 8px;
    }
    
    @include option(text-right) {
        padding-right: 8px;
    }
    
    @include option(logo, logo-light, logo-dark) {
        min-width: 16px;
        max-width: 64px;
        min-height: 16px;
        max-height: 64px;
        padding-bottom: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    
    @include option(logo) {
        background-image: url('#{asset('img/icn-logo-light.png')}');
    }
    
    @include option(logo-light) {
        background-image: url('#{asset('img/icn-logo-lightpng')}');
    }
    
    @include option(logo-dark) {
        background-image: url('#{asset('img/icn-logo-dark.png')}');
    }
}

@include component(icn-ul) {
    li {
        margin-bottom: 12px;
    }
}

@include component(toggle-icn) {
    @include option(hamburger) {
        display: inline-block;
        position: relative;
        top: -(2em / 3);
        @include size(1em, 1em / 7);
        border-radius: (1em / 6);
        transform: translateY(2em / 3 - .5em);
        background: $text-color;
        
        &:before, &:after {
            content: '';
            @include absolute((left: 0));
            display: inline-block;
            @include size(1em, 1em / 7);
            border-radius: (1em / 6);
            background: $text-color;
        }
        
        &:before {
            top: (1em / 3);
        }
        
        &:after {
            top: (2em / 3);
        }
        
        @include option(inv) {
            background: $inverse-text-color;
            
            &:before, &:after {
                background: $inverse-text-color;
            }
        }
        
        @include option(accent) {
            background: $accent-color;
            
            &:before, &:after {
                background: $accent-color
            }
        }
        
        @include option(to-close) {
            transform-origin: 50% 50%;
            transition: transform ($animation-duration / 2) ease, top ($animation-duration / 2) ease ($animation-duration / 2);
            
            &:before, &:after {
                transform-origin: 50% 50%;
            }
            
            &:before {
                transition: top ($animation-duration / 2) ease ($animation-duration / 2), opacity ($animation-duration / 2) ease ($animation-duration / 2), transform ($animation-duration / 2) ease ($animation-duration / 2);
            }
            
            &:after {
                transition: transform ($animation-duration / 2) ease, top ($animation-duration / 2) ease ($animation-duration / 2);
            }
            
            @include option(active) {
                top: -(1em / 3);
                transform: translateY(2em / 3 - .5em) rotate(45deg);
                transition: top ($animation-duration / 2) ease, transform ($animation-duration / 2) ease ($animation-duration / 2);
                
                &:before, &:after {
                    top: 0;
                }
                
                &:before {
                    transform: scale(.5);
                    opacity: 0;
                    transition: top ($animation-duration / 2) ease, opacity ($animation-duration / 2) ease, transform ($animation-duration / 2) ease;
                }
                
                &:after {
                    transform: rotate(-90deg);
                    transition: top ($animation-duration / 2) ease, transform ($animation-duration / 2) ease ($animation-duration / 2);
                }
            }
        }
    }
}
