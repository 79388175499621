$dark-contrast-default: $text-color;
$light-contrast-default: $inverse-text-color;

// Progress circle
$progress-circle-color: $accent-color;

// Icon prefix
$streamline-icon-prefix: strline;

// Font Weights
$fw-thin: 100;
$fw-hairline: 100;
$fw-extra-light: 200;
$fw-ultra-light: 200;
$fw-light: 300;
$fw-normal: 400;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-demi-bold: 600;
$fw-bold: 700;
$fw-strong: 700;
$fw-extra-bold: 800;
$fw-ultra-bold: 800;
$fw-black: 900;
$fw-heavy: 900;

// Bootstrap
$brand-primary: $accent-color !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$brand-colors: (
    primary: $brand-primary,
    success: $brand-success,
    info: $brand-info,
    warning: $brand-warning,
    danger: $brand-danger,
);

$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%) !default;

$input-border: $brand-primary !default;
$input-border-radius: 0;
$input-border-focus: rgba(lighten($inverse-main-color, 1 / 3 * 100%), .8);

// Social media colours / icons / stub classes
$social-colors: (
    facebook: #4c79bd,
    twitter: #56ccf1,
    linkedin: #0077b5,
    instagram: #c7c5b3,
    g-plus: #e04334,
    pinterest: #bd081b,
    tumblr: #35465c,
    reddit: #ff4500,
    whatsapp: #25d366,
    email: $accent-color,
) !default;

$social-icons: (
    facebook: facebook facebook-f facebook-with-circle,
    twitter: twitter twitter-with-circle,
    linkedin: linkedin linkedin-with-circle,
    instagram: instagram instagram-with-circle,
    g-plus: gplus gplus-with-circle,
    pinterest: pinterest pinterest-p pinterest-square,
    tumblr: tumblr tumblr-square,
    reddit: reddit reddit-alien reddit-square,
    email: envelope envelope-o,
);

$addtoany-icons: (
    g-plus: google_plus,
);

@each $color, $hex in $social-colors {
    %social-color-#{$color} {
        color: $hex;
    }
    
    %social-color-hover-#{$color} {
        color: lighten($hex, 5%);
    }
    
    %social-bg-#{$color} {
        background-color: $hex;
    }
    
    %social-bg-hover-#{$color} {
        background-color: lighten($hex, 5%);
    }
    
    %social-color-important-#{$color} {
        color: $hex !important;
    }
    
    %social-color-important-hover-#{$color} {
        color: lighten($hex, 100% / 15) !important;
    }
    
    %social-bg-important-#{$color} {
        background-color: $hex !important;
    }
    
    %social-bg-important-hover-#{$color} {
        background-color: lighten($hex, 100% / 15) !important;
    }
}

// Material Design shadows
$z-depth-1: 0 2px 5px 0 rgba(#000, .16), 0 2px 10px 0 rgba(#000, .12) !default;
$z-depth-1-half: 0 5px 11px 0 rgba(#000, .18), 0 4px 15px 0 rgba(#000, .15) !default;
$z-depth-2: 0 8px 17px 0 rgba(#000, .2), 0 6px 20px 0 rgba(#000, .19) !default;
$z-depth-3: 0 12px 15px 0 rgba(#000, .24), 0 17px 50px 0 rgba(#000, .19) !default;
$z-depth-4: 0 16px 28px 0 rgba(#000, .22), 0 25px 55px 0 rgba(#000, .21) !default;
$z-depth-5: 0 27px 24px 0 rgba(#000, .2), 0 40px 77px 0 rgba(#000, .22) !default;
