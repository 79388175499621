@font-face {
    font-family: 'Acrom';
    src: url('#{asset('fonts/acrom-light.eot')}');
    src: url('#{asset('fonts/acrom-light.eot')}?#iefix') format('embedded-opentype'),
         url('#{asset('fonts/acrom-light.woff2')}') format('woff2'),
         url('#{asset('fonts/acrom-light.woff')}') format('woff'),
         url('#{asset('fonts/acrom-light.ttf')}') format('truetype'),
         url('#{asset('fonts/acrom-light.svg')}#acromlight') format('svg');
    font-weight: $fw-light;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('#{asset('fonts/acrom-regular.eot')}');
    src: url('#{asset('fonts/acrom-regular.eot')}?#iefix') format('embedded-opentype'),
         url('#{asset('fonts/acrom-regular.woff2')}') format('woff2'),
         url('#{asset('fonts/acrom-regular.woff')}') format('woff'),
         url('#{asset('fonts/acrom-regular.ttf')}') format('truetype'),
         url('#{asset('fonts/acrom-regular.svg')}#acromregular') format('svg');
    font-weight: $fw-regular;
    font-style: normal;

}

@font-face {
    font-family: 'Acrom';
    src: url('#{asset('fonts/acrom-medium.eot')}');
    src: url('#{asset('fonts/acrom-medium.eot')}?#iefix') format('embedded-opentype'),
         url('#{asset('fonts/acrom-medium.woff2')}') format('woff2'),
         url('#{asset('fonts/acrom-medium.woff')}') format('woff'),
         url('#{asset('fonts/acrom-medium.ttf')}') format('truetype'),
         url('#{asset('fonts/acrom-medium.svg')}#acrommedium') format('svg');
    font-weight: $fw-medium;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('#{asset('fonts/acrom-bold.eot')}');
    src: url('#{asset('fonts/acrom-bold.eot')}?#iefix') format('embedded-opentype'),
         url('#{asset('fonts/acrom-bold.woff2')}') format('woff2'),
         url('#{asset('fonts/acrom-bold.woff')}') format('woff'),
         url('#{asset('fonts/acrom-bold.ttf')}') format('truetype'),
         url('#{asset('fonts/acrom-bold.svg')}#acrombold') format('svg');
    font-weight: $fw-bold;
    font-style: normal;
}
