@include component(hero) {
    @include part(title) {
        line-height: 1;
        
        h1, .h1 {
            font-size: 12vw;
            font-weight: $fw-light;
            line-height: 1;
        }
        
        @include part(phonetic, definition) {
            color: $inverse-faded-text-color;
            font-weight: $fw-light;
        }
        
        @include part(phonetic) {
            font-size: 4vw;
        }
    }
}
