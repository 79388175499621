#container > footer {
    background: $inverse-main-color;
    color: $inverse-text-color;
    z-index: 1;
    
    @include component(smallprint) {
        display: block;
        margin: 48px 0;
        @include policy(margin);
        @include font-size(14px);
        color: $faded-text-color;
    }
    
    @include component(credits) {
        background: darken($inverse-main-color, 5%);
        color: $inverse-faded-text-color;
        @include font-size(14px);
        
        a {
            color: mix($inverse-faded-text-color, $inverse-main-color, 75%);
            
            &:hover {
                color: lighten(mix($inverse-faded-text-color, $inverse-main-color, 75%), 10%);
            }
        }
    }
}
