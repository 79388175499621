@include component(section) {
    position: relative;
    overflow: hidden;
    z-index: 1;
    
    @include option(style-bg-img) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    
    @include option(type-banner) {
        font-weight: $fw-regular;
    }
}
