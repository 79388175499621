@mixin btn-style($color, $hex) {
    @include option($color) {
        background: $hex;
        color: color-contrast($hex);
        
        @include part(icn) {
            @include component(icn) {
                background-image: color-contrast($hex, url('#{asset('img/icn-logo-light.png')}'), url('#{asset('img/icn-logo-dark.png')}'));
            }
        }
        
        @include hover {
            background: lighten($hex, if($hex == $accent-color, 5%, 2.5%));
        }
    }
}

@include component(btn) {
    display: inline-flex;
    justify-content: flex-start;
    border: none;
    align-items: stretch;
    flex-wrap: wrap;
    line-height: 1;
    @include font-size(20px);
    
    &:hover {
        @include part(icn) {
            @include component(icn) {
                transform: rotateY(-360deg);
            }
        }
    }
    
    @include part(group) {
        @include clearfix;
        
        @include important {
            margin: -8px -16px;
        }
        
        @include component(btn) {
            float: left;
            margin: 8px 16px;
        }
        
        @include option(center) {
            @extend %flex-row;
            justify-content: center;
        }
    }
    
    @include part(icn) {
        padding: 12px;
        border-right: 1px solid rgba(#000, .125);
        
        @include component(icn) {
            width: calculate-rem(24px);
            transition: all $animation-duration * (4 / 3);
        }
    }
    
    @include part(content) {
        padding: 14px;
    }
    
    @include option(sm) {
        @include part(content) {
            padding: 8px;
            @include font-size($font-base-size);
            line-height: 1;
            text-align: center;
            white-space: nowrap;
        }
    }
    
    @include option(block) {
        display: flex;
        width: 100%;
    }
    
    @include btn-style(accent, $accent-color);
    @include btn-style(primary, $primary-color);
    @include btn-style(secondary, $secondary-color);
}
