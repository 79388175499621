@include component(profile) {
    background: $main-color;
    color: $text-color;
    
    @include part(content) {
        padding: 48px;
    }
    
    @include part(section) {
        margin: 24px 0;
        @include policy(margin);
    }
    
    @include part(avatar) {
        width: 100%;
        min-height: 100%;
        padding-bottom: 100%;
        overflow: hidden;
    }
}
